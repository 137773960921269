<template>
  <div id="contact-modal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h2>{{ $t("contactus.heading") }}</h2>
          <div v-if="formUrl" class="iframe-wrapper w-full overflow-hidden">
            <iframe
              :src="formUrl"
              class="contact-iframe w-full overflow-hidden"
              id="form-7012o000001f2GFAAY"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { settingsData } = useSettings();
const { locale } = useI18n();
const formUrl = ref(settingsData.value.contact_form.cached_url);
let currentLang = 'en';

const fetchSettings = () => {
  const { settingsData } = useSettings();
  formUrl.value = settingsData?.value?.contact_form.cached_url;
  const { $i18n } = useNuxtApp();
  currentLang = $i18n.locale.value;
};

// Re-fetch settings data when the locale changes
watch(locale, () => {
  fetchSettings();
});

// Use onMounted to perform async operations
onMounted(() => {
  fetchSettings();

  window.addEventListener("message", (e) => {
    // The Sales inquiry script sends data about a form submission
    if (e.origin.indexOf('https://cloud.mc.metso.com')<0){
      return;
    }
    /*if(e.data && e.data.type === "mc-data") {
      console.log('postmessage received mc-data call succesfully');
    }*/
    // Listen for "sales inquiry" script (from cloud.mc.metso.com) to request data
    if(e.data && e.data['task'] === 'pardotSendPageData'){
        sendSiteData();
    }
  }, false);

  // Send site data when component has been mounted, to initialize the contact form script in iframe
  //  (iframe script is requesting this before the component has been mounted)
  sendSiteData();
});
const sendSiteData = (formId) => {
  const iframe = document.getElementById('form-7012o000001f2GFAAY');
  var message = {
      task: "siteData",
      formID: 'form-7012o000001f2GFAAY',
      referringPageUrl: window.location.href,
      lang: currentLang,
      pageName: document.title,
      masterName: 'Metso Liveroom', //Eg. "Contact sales"
      masterUrl: '/' //Eg. '/contact/contact-sales/'
  };
  
  var targetOrigin = 'https://cloud.mc.metso.com';
  try {
    iframe.contentWindow.postMessage(message, targetOrigin);
  } catch (e) {
    // Do nothing;
  }
}

</script>

<style scoped lang="scss">
.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  border-bottom: 0;
}
.contact-iframe {
  height: 1250px;
}
</style>
