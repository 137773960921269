<template>
  <div id="feedback-modal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="feedback-modal-content">
            <h2>
              {{
                showThankyou
                  ? $t("feedback.thankYouTitle")
                  : $t("feedback.title")
              }}
            </h2>
            <span class="feedback-modal-text">{{
              showThankyou
                ? $t("feedback.thankYouDescription")
                : $t("feedback.description")
            }}</span>
            <div>
              <div v-if="!showThankyou" class="feedback-modal-smilies">
                <button @click="payload.rating = 1">
                  <img
                    v-if="payload.rating === 1"
                    src="@/assets/img/icons/sm0selected.svg"
                  />
                  <img
                    v-if="payload.rating != 1"
                    src="@/assets/img/icons/sm0.svg"
                  />
                </button>
                <button @click="payload.rating = 2">
                  <img
                    v-if="payload.rating === 2"
                    src="@/assets/img/icons/sm1selected.svg"
                  />
                  <img
                    v-if="payload.rating != 2"
                    src="@/assets/img/icons/sm1.svg"
                  />
                </button>
                <button @click="payload.rating = 3">
                  <img
                    v-if="payload.rating === 3"
                    src="@/assets/img/icons/sm2selected.svg"
                  />
                  <img
                    v-if="payload.rating != 3"
                    src="@/assets/img/icons/sm2.svg"
                  />
                </button>
                <button @click="payload.rating = 4">
                  <img
                    v-if="payload.rating === 4"
                    src="@/assets/img/icons/sm3selected.svg"
                  />
                  <img
                    v-if="payload.rating != 4"
                    src="@/assets/img/icons/sm3.svg"
                  />
                </button>
                <button @click="payload.rating = 5">
                  <img
                    v-if="payload.rating === 5"
                    src="@/assets/img/icons/sm4selected.svg"
                  />
                  <img
                    v-if="payload.rating != 5"
                    src="@/assets/img/icons/sm4.svg"
                  />
                </button>
              </div>
              <div v-if="!showThankyou" class="feedback-modal-textfield">
                <span class="feedback-modal-textfield-label">{{
                  $t("feedback.label")
                }}</span>
                <textarea
                  v-model="payload.text"
                  rows="5"
                  class="feedback-modal-textfield-textarea"
                ></textarea>
                <div v-html="$t('feedback.nopersonal')"></div>
              </div>
              <div class="feedback-modal-submit">
                <button
                  v-if="!showThankyou"
                  :disabled="payload.rating === null"
                  class="submit-button"
                  @click="onFeedbackSubmit()"
                >
                  {{ $t("general.submit") }}
                </button>
                <button
                  v-if="showThankyou"
                  class="submit-button"
                  @click="onModalSwitch"
                >
                  {{ $t("general.close") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { addDoc, collection } from "firebase/firestore";
const { $firestore, $bootstrap } = useNuxtApp();
const showThankyou = ref(false);
const payload = ref({
  rating: null,
  text: "",
});

async function onFeedbackSubmit() {
  if (payload.value.rating !== null) {
    // Add a new document with auto generated id
    const docRef = await addDoc(collection($firestore, "feedback"), {
      feedback: payload.value,
      uid: "",
      timestamp: Date.now(),
    });
    showThankyou.value = true;
    console.log("Feedback Sent with ID: ", docRef.id);
  }
}
function onModalSwitch() {
  const modalElement = document.getElementById("feedback-modal");
  if (modalElement) {
    const modal = $bootstrap.Modal.getOrCreateInstance(modalElement);
    modal.hide();
  }
}
</script>

<script>
export default {
  data() {
    return {
      modal: null,
      rating: null,
      text: "",
      showThankyou: false,
    };
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 0;
  z-index: 99;
}

.feedback-modal-container {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  --notchSize: 30px;

  clip-path: polygon(
    0% 0%,
    0% 0%,
    0% 0%,
    100% 0%,
    100% calc(100% - var(--notchSize)),
    calc(100% - var(--notchSize)) 100%,
    100% 100%,
    0% 100%
  );
}

.feedback-modal-container {
  padding: 1.5rem;
}

.feedback-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.feedback-modal-header {
  display: flex;
  flex-direction: row;
}

.feedback-modal-title {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  font-size: 1.75rem;
}
.feedback-modal-text {
  font-size: 1.25rem;
}
.feedback-modal-smilies {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feedback-modal-textfield {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}
.feedback-modal-textfield-textarea {
  margin-top: 0.5rem;
  resize: none;
  border: 1px solid #d6d6d6;
}
.close {
  margin-top: 0rem;
  margin-right: 0rem;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
button {
  background-color: #ffffff;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
.submit-button {
  font-size: 1.125rem;
  background-color: #eb2814;
  border: none;
  padding: 1rem 4rem;
  margin: 2rem 0 0;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
</style>
