<template>
  <div id="promotion-modal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="promotion" class="modal-body">
          <h2>{{ promotion.heading }}</h2>
          <RichTextRenderer :blok="promotion.body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { $bootstrap } = useNuxtApp();
const { settingsData } = useSettings();
const settings = settingsData.value;

const promotion = computed(() => {
  if (settings.SiteWideComponents) {
    return (
      settings.SiteWideComponents.find((x) => {
        return x.component === "PromotionModal";
      }) || null
    );
  }
  return null;
});

const handleModalOpen = () => {
  const modalElement = document.getElementById("promotion-modal");
  if (modalElement) {
    const modal = $bootstrap.Modal.getOrCreateInstance(modalElement);
    modal.show();
  }
};

onMounted(() => {
  if (promotion.value && promotion.value.showModal) {
    handleModalOpen();
  }
});
</script>

<style scoped lang="scss">
.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 0;
  z-index: 99;
}
</style>
