<template>
  <div id="my-metso-modal" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="onlyMyMetso" class="modal-body">
          <h2>{{ onlyMyMetso.heading }}</h2>
          <RichTextRenderer :blok="onlyMyMetso.body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { settingsData } = useSettings();
const settings = settingsData.value;
export default {
  name: "MyMetsoModal",
  data() {
    return {
      modal: null,
    };
  },
  computed: {
    onlyMyMetso() {
      if (settings.SiteWideComponents) {
        return settings.SiteWideComponents.find((x) => {
          return x.component === "My Metso Modal";
        });
      }
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 0;
  z-index: 99;
}
</style>
